import * as React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/post-card"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
	const subtitle = data.site.siteMetadata.subtitle
	let posts = data.allMarkdownRemark.nodes
	const devToArticles = data.allPosts.nodes
	posts = posts.concat(devToArticles)

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} subtitle={subtitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} subtitle={subtitle}>
      <Seo title="All posts" />
      <ol className="post-list">
        {posts.map(post => {
					const frontmatter = post.frontmatter || post
          const title = frontmatter.title
					const slug = post.fields.slug ? post.fields.slug : `/${post.slug}/`
          return (
            <PostCard
								key={post.id}
								slug={slug}
								title={title}
								date={frontmatter.date}
								description={frontmatter.description}
								tags={frontmatter.tags || []}
								devTo={post.fields.dev_to}
						/>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
				subtitle
      }
    }
    allMarkdownRemark(filter: { frontmatter: { published: { eq: true } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
				id
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
					tags
        }
      }
    }
		allPosts(
			filter: { published: { eq: true } }
		) {
			nodes {
				id
				title
				description
				date: published_at(formatString: "MMMM DD, YYYY")
				tags: tag_list
				slug
				fields {
					dev_to
				}
			}
		}
  }
`
