import React from "react"

import { Link } from "gatsby";
import Tag from "../components/tag"

import "devicon/devicon.min.css";

const PostCard = (props) => {
    const { slug, title, date, description, tags, devTo } = props
		const publishedOnDevTo = (devTo) => {
			return devTo ?
			"on DEV.to"
			: null
		}

    return (
			<li className="post-card">
				<div className="post-card-tag-list">
					{tags.map((tag, i) => (
						<span key={tag} className="postcard-tag"><Tag name={tag} /></span>
					))}
				</div>
				<Link to={slug} itemProp="url">
					<article
						className="post-list-item"
						itemScope
						itemType="http://schema.org/Article"
					>
						<header>
							<h2>
								<span itemProp="headline">{title}</span>
							</h2>
							<small>{date} {publishedOnDevTo(devTo)}</small>
						</header>
						<section>
							<p
								dangerouslySetInnerHTML={{
									__html: description,
								}}
								itemProp="description"
							/>
						</section>
					</article>
				</Link>
			</li>
    )
}

export default PostCard
